<template>
  <v-container>
    <v-layout class="d-flex-column justify-center align-center">
      <div class="container">
        <h3>{{ $t('lienVietConfig.infoValidationError') }} !</h3>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
